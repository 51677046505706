import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"

const Content = styled.div`
  max-width: 1200px;
  margin: 3rem auto 0 auto;
  display: flex;
  flex-direction: row-reverse;
  scroll-behavior: smooth;
  p {
    word-break: normal;
    hyphens: auto;
    margin-bottom: 1.45em;
    min-height: 1em;
  }
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
  }
`

const Parlament = styled.div`
  padding: 0 1rem;
  position: relative;
  width: 66%;
  margin-left: auto;

  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`

const Kopf = styled(Parlament)``
const Info = styled.div`
  padding: 10.7rem 1rem 0 1rem;
  align-self: flex-start;

  color: red;
  width: 33%;
  background: transparent;
  h2 {
    margin-top: 48px;
    margin-bottom: 5.1em;
    font-size: 2em;
    line-height: 1.45em;
    background: transparent;
  }
  @media only screen and (max-width: 1200px) {
    h2 {
      margin-bottom: 0.62em;
    }
  }
  @media only screen and (max-width: 1080px) {
    width: 100%;
    transform: translateY(0);
  }
`
class Explained extends React.Component {
  render() {
    const data = this.props.data.prismic.allGedankenexperiment_explainers
      .edges[0].node

    return (
      <Layout location={this.props.location.pathname}>
        <Content id="top">
          <Kopf id="kopf">{RichText.render(data.body)}</Kopf>
          <Info>
            <h2>15. Mai - 15. August GEDANKENEXPERIMENT</h2>
          </Info>
        </Content>
      </Layout>
    )
  }
}

export default Explained

export const pageQuery = graphql`
  query GedankenExplainedQuery {
    prismic {
      allGedankenexperiment_explainers(lang: "de-at") {
        edges {
          node {
            body
          }
        }
      }
    }
  }
`
